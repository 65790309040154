/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

enum ResourceType {
  ACCOUNT = 'account',
  DOCUMENT = 'document',
}

interface GlobalAutocompleteOptionProps {
  data: Record<string, any>;
  index: string;
  focused?: boolean;
}

const StyledGlobalAutocompleteOption = styled.div<{ focused: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  //padding: 4px;
  background: ${(props) => (props.focused ? cssVars.blue + '30' : 'transparent')};
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  flex: 1;
  font-size: 1rem;
`;

const Icon = styled.span`
  color: ${cssVars.charcoal};
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Reference = styled.span``;

const getResourceType = (index: string) => {
  switch (true) {
    case index.includes('accounts'):
      return ResourceType.ACCOUNT;
    case index.includes('documents'):
      return ResourceType.DOCUMENT;
    default:
      return null;
  }
};

const icons = {
  [ResourceType.ACCOUNT]: 'building-memo' as IconName,
  [ResourceType.DOCUMENT]: 'file' as IconName,
};

function GlobalAutocompleteOption({ data, ...props }: GlobalAutocompleteOptionProps) {
  const resourceType = getResourceType(props.index);
  if (!resourceType) return null;
  const icon = icons[resourceType];
  const title = data.title ?? data.name;

  const reference = resourceType === ResourceType.ACCOUNT ? data.accountNumber : data.id;

  return (
    <StyledGlobalAutocompleteOption focused={props.focused}>
      <Icon>
        <FontAwesomeIcon icon={['fas', icon]} />
      </Icon>
      <Info>
        <Title>{title}</Title>
        <Reference>{reference}</Reference>
        {/*<Reference>{data._score}</Reference>*/}
      </Info>
    </StyledGlobalAutocompleteOption>
  );
}

export default GlobalAutocompleteOption;
