/** @format */

import axios from 'axios';

export class Realm {
  id: string;
  realm: string;

  static list(search: string): Promise<{ payload: Array<Realm> }> {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/sso/realms`, {
          params: { search },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static autocomplete(search: string, config: { signal }): Promise<{ payload: Array<Realm> }> {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/sso/realms`, {
          signal: config.signal,
          params: { search },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(name: string): Promise<{ message: string; paginated: boolean; payload: Realm }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/realms/${name}`).then((res) => ({ ...res.data }));
  }
}
