/** @format */

export enum DocumentScheduleTypeId {
  ACCEPTED = 'accepted',
  CREATED = 'created',
  PROVIDED = 'provided',
  OTHER = 'other',
  REJECTED = 'rejected',
  VALIDATED = 'validated',
}

export enum DocumentScheduleTypeIdTranslation {
  ACCEPTED = 'accepted',
  CREATED = 'created',
  PROVIDED = 'provided',
  OTHER = 'other',
  REJECTED = 'rejected',
}
