/** @format */

import Button from '@atoms/Button';
import NavLink from '@atoms/NavLink';
import EnvironmentLabel from '@atoms/EnvironmentLabel';
import Text from '@atoms/Text';
import logo from '@common/application/assets/images/logo-cropped.png';
import { useFullScreen } from '@common/application/hooks/useFullscreen/src';
import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import { useWindowDimensions } from '@common/application/hooks/useWindowDimensions';
import Feature from '@common/prototypes/Feature';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileNav, { ILink } from '@organisms/MobileNav';
import NotificationButton from '@organisms/NotificationButton';
import Navigation from '@quarks/Navigation';
import NavigationPanel from '@quarks/NavigationPanel';
import { useTour } from '@reactour/tour';
import UserButton from '@templates/App/UserButton';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import GlobalSearch from '@molecules/GlobalSearch';

const blackTextCss = css`
  color: black;
`;

const StyledLogoWrapper = styled.div`
  cursor: pointer;

  height: 32px;
`;

const roles = {
  '': -1,
  User: 0,
  'Super User': 1,
  Administrator: 2,
  'Super Administrator': 3,
};

export function Header() {
  const { width } = useWindowDimensions();
  const { fullScreen, toggle } = useFullScreen();
  const screenSizeIcon = fullScreen ? regular('minimize') : regular('maximize');
  const { isAdmin, isSuperAdmin } = useIsAdmin();
  const icon = fullScreen ? regular('minimize') : regular('maximize');
  const { pathname } = useRouter();
  const shrink = useMemo(() => width < 1214, [width]);
  // This is obviously not a perfect solution but better than nothing I think
  const isDesktop = !('ontouchstart' in window || navigator.maxTouchPoints > 0);
  const { setIsOpen, isOpen } = useTour();

  const links: ILink[] = [
    { href: '/', text: <FormattedMessage defaultMessage="Home" description="Home page link in the navigation bar" />, active: pathname.startsWith('/account') },
    { href: '/admin', text: <FormattedMessage defaultMessage="Administration" description="Administration page link in the navigation bar" />, adminOnly: true },
    { href: '/help', text: <FormattedMessage defaultMessage="Help" description="Help page link in the navigation bar" /> },
    // { href: '/resources', text: <FormattedMessage defaultMessage="Resources" description="Resources page link in the navigation bar" />, featureFlag: 'resource' },
    { href: '/about', text: <FormattedMessage defaultMessage="About" description="About page link in the navigation bar" /> },
  ];

  if (width < 760) {
    return <MobileNav links={links} />;
  }

  const showEnv = isSuperAdmin;

  return (
    <Navigation>
      <NavigationPanel>
        <StyledLogoWrapper>
          <div
            css={css`
              width: 64px;
              display: flex;
              justify-content: center;
            `}
          >
            <Link passHref href={'/'}>
              <a>
                <Image
                  title={'Home'}
                  css={css`
                    cursor: pointer;
                  `}
                  src={logo}
                  // layout={'fill'}
                  width={showEnv ? 32 : 64}
                  height={showEnv ? 16 : 32}
                  alt="trackup"
                />
              </a>
            </Link>
          </div>
          {showEnv && <EnvironmentLabel />}
        </StyledLogoWrapper>
        {links.map((link) => {
          if (link.adminOnly && !isAdmin) return null;

          return <NavLink href={link.href} text={link.text} active={link.active} key={`navLink${link.href}`} />;
        })}
        {Feature('resource').render(
          <Text.Link active={pathname.startsWith('/resources')} href={'/resources'} css={blackTextCss}>
            <FormattedMessage defaultMessage="Resources" description="Resources page link in the navigation bar" />
          </Text.Link>,
        )}
        <span className={'tour-start-btn'}>
          <Text.Link active={isOpen} onClick={() => setIsOpen(true)} css={blackTextCss}>
            <FormattedMessage defaultMessage="Demo" />
          </Text.Link>
        </span>
      </NavigationPanel>

      <>
        <GlobalSearch />

        <NavigationPanel>
          <UserButton />
          <NotificationButton />
          {isDesktop && (
            <Button onClick={toggle}>
              <FontAwesomeIcon icon={screenSizeIcon} />
              <Text.Plain>
                {fullScreen ? (
                  <FormattedMessage defaultMessage="Exit Fullscreen" description="Menu bar exit fullscreen button text" />
                ) : (
                  <FormattedMessage defaultMessage="Enter Fullscreen" description="Menu bar enter fullscreen button text" />
                )}
              </Text.Plain>
            </Button>
          )}
        </NavigationPanel>
      </>
    </Navigation>
  );
}
