/** @format */

import axios from 'axios';

export class Country {
  alpha2: string;
  name: string;
  latitude: number;
  longitude: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Country; sort }>; last_page: number; total: { value: number } }> {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/countries`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify([{ 'name.keyword': 'asc' }, { 'alpha2.keyword': 'asc' }]),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static autocomplete(query, config) {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/countries/search`, {
          signal: config.signal,
          params: {
            sequence_id: 1,
            query,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static getById(countryId): Promise<{ message: string; paginated: boolean; payload: Country }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/countries/${countryId}`).then((res) => ({ ...res.data }));
  }
}
