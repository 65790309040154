/** @format */

import useDefaultSettings from '@common/application/hooks/useDefaultSettings/useDefaultSettings';
import useKeyString from '@common/application/hooks/useKeyString/useKeyString';
import useUserSettings, { SettingsKey } from '@common/application/hooks/useUserSettings/useUserSettings';
import React, { useCallback, useEffect } from 'react';

export enum ShortcutAction {
  SAVE = 'save',
  LOAD = 'load',
  ATTACHMENTS = 'attachments',
  SAVEAS = 'saveAs',
  DOWNLOAD = 'download',
  DOWNLOAD_ALL = 'downloadAll',
  SAVETEMPLATE = 'saveTemplate',
  TOGGLEPERCENT = 'togglePercent',
  TOGGLEIMAGES = 'toggleImages',
  NEWDOC = 'newDoc',
  MAIL = 'mail',
  PRINT = 'print',
}

function UseKeyboardShortcuts(config: Partial<Record<ShortcutAction, Function>>) {
  const defaultSettings = useDefaultSettings();

  const { settings: keyboardSettings } = useUserSettings(SettingsKey.KEY, defaultSettings.keyMap);
  const { processKeypress } = useKeyString();

  const checkKeypress = useCallback(
    (e: KeyboardEvent) => {
      const triggerShortcut = (e: KeyboardEvent, action: ShortcutAction) => {
        if (config[action]) {
          e.preventDefault();
          return config[action];
        }
        return () => {};
      };
      //eString is a stringified representation of the keypress event which can easily be checked for equality against saved shortcuts
      const eString = processKeypress(e);
      for (const [key, val] of Object.entries(keyboardSettings)) {
        if (val === eString) {
          const shortCutFn = triggerShortcut(e, key as ShortcutAction);
          shortCutFn();
        }
      }
    },
    [keyboardSettings, processKeypress],
  );

  // Add event listener for keypress events
  useEffect(() => {
    document.addEventListener('keydown', checkKeypress, false);
    return () => {
      document.removeEventListener('keydown', checkKeypress, false);
    };
  }, [checkKeypress]);

  return keyboardSettings;
}

export default UseKeyboardShortcuts;
