/** @format */

import { Resource } from '@common/application/enums/Resource';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import axios from 'axios';

export class CompanyBrand {
  id: number;
  companyId: number;
  brand: string;
  logo: ResourceUrl;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  constructor(companyId: number, brand: string, logo: ResourceUrl) {
    this.companyId = companyId;
    this.brand = brand;
    this.logo = logo;
  }

  static list(
    query,
    sort,
    queryOpts?: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: CompanyBrand; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/company/brands`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/company/brands/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/company/brands/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(updatedCompany: CompanyBrand, id: number): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/company/brands/${id}`, { payload: updatedCompany }).then(async (res) => {
      const { signedUrl } = res.data.payload;
      if (signedUrl) {
        await uploadFileToSignedUrl(signedUrl, updatedCompany.logo.url as File, res.data.payload, 'logo', Resource.COMPANY_BRAND);
      }
      return { ...res.data };
    });
  }

  add(fileName: string, fileExt: string): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/company/brands`, {
        payload: {
          brand: this.brand,
          companyId: this.companyId,
          logo: { ext: fileExt, name: fileName, dataPresent: !!this.logo.url },
        },
      })
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        await uploadFileToSignedUrl(signedUrl, this.logo.url as File, res.data.payload, 'logo', Resource.COMPANY_BRAND);
        return { ...res.data };
      });
  }
}
