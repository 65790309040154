/** @format */

import axios from 'axios';

export class BannerNotification {
  id: number;
  message: string;
  startDate: Date;
  endDate: Date;
  realm: string;
  countdown?: boolean;

  static getForRealm() {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/bannernotifications`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static create(notification: { message: string; startDate: Date; endDate: Date; countdown?: boolean; href?: string }) {
    return axios.post(
      `${window.location.protocol}//${window.location.host}/api/v1/bannernotifications`,
      { payload: notification },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  static updateOne(notificationId: number, notification: { message: string; startDate: Date; endDate: Date; countdown?: boolean; href?: string }) {
    return axios.patch(
      `${window.location.protocol}//${window.location.host}/api/v1/bannernotifications/${notificationId}`,
      { payload: notification },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
  static deleteOne(notificationId: number) {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/bannernotifications/${notificationId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
