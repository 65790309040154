/** @format */

// import { Checkbox, State } from '@atoms/Checkbox';
import useLocalStorage from '@common/application/hooks/useLocalStorage/useLocalStorage';
import styled from '@emotion/styled';
import { Checkbox } from '@progress/kendo-react-inputs';
import { StepType, useTour } from '@reactour/tour';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
export const tourHideStorageKey = `TU_Hide_v4_Demo`;
const routerPaths = { home: '/', account: '/account/[account_id]', doc: '/account/[account_id]/document/[document_id]' };

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-weight: bold;
  }
`;
const StepWrapper = ({ children, path }: { children: ReactNode; path: string }) => {
  const [hideTour, setHideTour] = useLocalStorage(tourHideStorageKey, { [routerPaths.home]: false, [routerPaths.doc]: false, [routerPaths.account]: false });
  const toggleShowTour = (bool: boolean) => {
    setHideTour((t) => ({ ...t, [path]: bool }));
  };

  return (
    <StyledStep>
      <div>{children}</div>
      <div>
        <Checkbox value={hideTour[path]} label={<FormattedMessage defaultMessage="Don't show again" />} onClick={() => toggleShowTour(!hideTour[path])} />
      </div>
    </StyledStep>
  );
};

export const useTourSteps = (path: string) => {
  const [hideTour, setHideTour] = useLocalStorage(tourHideStorageKey, { [routerPaths.home]: false, [routerPaths.doc]: false, [routerPaths.account]: false });
  const { setIsOpen: setIsTourOpen } = useTour();
  const hide = useMemo(() => {
    const userHide = hideTour[path];
    switch (path) {
      case routerPaths.home:
        return userHide;
      case routerPaths.account:
        return userHide;
      case routerPaths.doc:
        return userHide;
      default:
        return true;
    }
  }, [hideTour, path, routerPaths.account, routerPaths.doc, routerPaths.home]);

  const steps = useMemo(() => {
    const dashboardSteps: StepType[] = [
      {
        content: () => (
          <StepWrapper path={path}>
            <h3>
              <FormattedMessage defaultMessage="Welcome to the new version of TrackUp!" />
            </h3>
            <p>
              <FormattedMessage defaultMessage="Let's take a quick tour of the new layout..." />
            </p>
            <p>
              <FormattedMessage defaultMessage="You can return to this tour at any time by clicking this 'Demo' button" />
            </p>
          </StepWrapper>
        ),
        selector: '.tour-start-btn',
        position: 'center',
      },
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="You can use this search bar to find accounts or documents by searching for the name or reference ID" />
            </p>
          </StepWrapper>
        ),
        selector: '.global-search',
      },
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="You can use these tabs to view your favourite accounts, recently viewed accounts, and search for accounts by category" />
            </p>
          </StepWrapper>
        ),
        selector: '.favourite-accounts',
        highlightedSelectors: ['.favourite-accounts', '.recent-accounts', '.tagged-accounts'],
      },
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="These tabs are used to view your most recently created documents, recently viewed documents, and search for documents by category" />
            </p>
          </StepWrapper>
        ),
        selector: '.recent-created-documents',
        highlightedSelectors: ['.recent-created-documents', '.recent-viewed-documents', '.tagged-documents'],
      },
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="This tab will show you the original TrackUp homepage layout" />
            </p>
          </StepWrapper>
        ),
        selector: '.classic-view',
      },
    ];

    const accountSteps: StepType[] = [
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="Click this icon to add or remove it from your favourites" />
            </p>
          </StepWrapper>
        ),
        selector: '.favourite-icon',
      },
    ];

    const docSteps: StepType[] = [
      {
        content: () => (
          <StepWrapper path={path}>
            <p>
              <FormattedMessage defaultMessage="You can now add category tags to your documents. If you would like more information about this, please contact your country's TrackUp Administrator" />
            </p>
          </StepWrapper>
        ),
        selector: '.document-tag-section',
        // position: 'center',
      },
    ];

    switch (path) {
      case routerPaths.home:
        return [...dashboardSteps];
      case routerPaths.account:
        return [...accountSteps];
      case routerPaths.doc:
        return [...docSteps];
      default:
        return [];
    }
  }, [path]);

  return { hide, steps, setIsTourOpen };
};

export default useTourSteps;
