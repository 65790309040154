/** @format */

import { RecordStatus } from '@common/application/enums';
import { Resource } from '@common/application/enums/Resource';
import convertFileToB64 from '@common/application/helpers/convertFileToB64';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import { Tag } from '@common/model/apiClient/Tag';
import { DocumentStatus } from '@molecules/DocumentSummary';
import { TagWithCategory } from '@molecules/TagMenu';
import axios, { AxiosResponse } from 'axios';

export interface AccountTag {
  accountId: number;
  companyId: number;
  tag: Tag;
  tagId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface ReturnedAccount extends Omit<Account, 'tags'> {
  tags: { tag: TagWithCategory }[];
}
export class Account {
  id: number;
  logo: ResourceUrl;
  name: string;
  companyId: number;
  description: string;
  accountNumber: string;
  nationalAccountNumber: string;
  extraAccountNumbers: Array<string>;
  industryId: number;
  industryAwaitingApproval?: boolean;
  industryProposedBy?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdCount: number;
  providedCount: number;
  acceptedCount: number;
  savingsTotal?: string;
  totalCount?: string;
  tags?: Array<TagWithCategory>;

  constructor(name, logo: ResourceUrl, companyId, description, accountNumber, nationalAccountNumber, extraAccountNumbers, industryId?, tags?) {
    this.name = name;
    this.logo = logo;
    this.companyId = companyId;
    this.description = description;
    this.accountNumber = accountNumber;
    this.nationalAccountNumber = nationalAccountNumber;
    this.extraAccountNumbers = extraAccountNumbers;
    this.industryId = industryId;
    this.tags = tags;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Account; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/accounts`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static autocomplete(query, config) {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/accounts/search`, {
          signal: config.signal,

          params: {
            sequence_id: 1,
            query,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: ReturnedAccount }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/accounts/${id}`).then((res) => ({ ...res.data }));
  }

  static getDocumentSummary(id: number): Promise<{ message: string; paginated: boolean; payload: Array<{ documentStatus: DocumentStatus; count: number }> }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/accounts/${id}/documentschedules`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/accounts/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/accounts/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(locale): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/accounts/add`, {
      // responseType: 'arraybuffer',
      params: {
        locale: locale,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static getByTag(accountTags: number[], documentTags: number[]): Promise<AxiosResponse<{ payload: Array<Account> }>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/accounts/tags`, {
      // responseType: 'arraybuffer',
      params: {
        accountTags: JSON.stringify(accountTags),
        documentTags: JSON.stringify(documentTags),
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static async postAddTemplate(file: File): Promise<Record<string, unknown>> {
    const fileString = await convertFileToB64(file);
    return axios.post(
      `${window.location.protocol}//${window.location.host}/api/v1/templates/accounts/add`,
      { payload: fileString },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  create(fileName: string, fileExt: string): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/accounts`, {
        payload: {
          name: this.name,
          logo: {
            // data: this.logo,
            name: fileName,
            ext: fileExt,
          },
          companyId: this.companyId,
          description: this.description,
          accountNumber: this.accountNumber,
          nationalAccountNumber: this.nationalAccountNumber,
          extraAccountNumbers: this.extraAccountNumbers,
          industryId: this.industryId,
        },
      })
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        await uploadFileToSignedUrl(signedUrl, this.logo.url as File, res.data.payload, 'logo', Resource.ACCOUNT_LOGO);
        return { ...res.data };
      })
      .catch((error) => {
        throw error;
      });
  }
  static update(updatedAccount: Partial<Account>, id: number, newFileExt?: string): Promise<Record<string, unknown>> {
    // console.log(updatedAccount);
    // return;
    const newCategories = updatedAccount.tags?.filter((c) => c.status === RecordStatus.NEW).map((c) => ({ tagId: c.id }));
    // const deletedCategories = updatedAccount.tags?.filter((c) => c.status === RecordStatus.DELETED);
    const deletedCategories = updatedAccount.tags
      ?.filter((c) => c.status === RecordStatus.DELETED)
      .map((c) => ({
        accountId_tagId: {
          accountId: id,
          tagId: c.id,
        },
      }));
    const tags = { create: newCategories, delete: deletedCategories };
    const payload = { ...updatedAccount, logo: newFileExt ? { fileExt: newFileExt } : null, tags };
    console.log(payload);
    // return;
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/accounts/${id}`, { payload })
      .then(async (res) => {
        if (updatedAccount?.logo?.url && res.data.payload.signedUrl) {
          const { signedUrl } = res.data.payload;
          await uploadFileToSignedUrl(signedUrl, updatedAccount.logo.url as File, res.data.payload, 'logo', Resource.ACCOUNT_LOGO);
        }
        return { ...res.data };
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
}
