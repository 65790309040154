/** @format */

import GlobalAutocompleteOption from '@atoms/AutocompleteOptions/GlobalAutocompleteOption';
import Button from '@atoms/Button';
import { cssVars } from '@atoms/GlobalStyles';
import useIsNavigating from '@common/application/hooks/useIsNavigating/useIsNavigating';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputPrefix, InputSuffix } from '@progress/kendo-react-inputs';
import NavigationPanel from '@quarks/NavigationPanel';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useThrottledCallback } from 'use-debounce';
import { AutoComplete, ListItemProps } from '@progress/kendo-react-dropdowns';

interface GlobalSearchProps {}

const StyledGlobalSearch = styled(NavigationPanel)`
  flex: 1;
  margin-inline: 20px;
  height: 30px;
  gap: 0;
  &:focus-within {
    button {
      border-color: ${cssVars.grey};
    }
  }
  //width: 50%;
`;

const StyledAutocomplete = styled(AutoComplete)`
  margin-right: 0 !important;
  border-radius: 3px 0 0 3px;
  border-color: ${cssVars.lightGrey};
  :focus,
  :active,
  :focus-within {
    border-color: ${cssVars.grey};
    box-shadow: none;
    outline: none;
    * {
      outline: none;
      box-shadow: none;
    }
  }
`;

// const GoButton = styled.button`
const GoButton = styled(Button)<{ className: string }>`
  width: 34px;
  height: 30px !important;
  min-height: 30px;
  min-width: 34px;
  padding: 0;
  border-radius: 0 3px 3px 0;
  //border-width: 0 0 0 1px;
  border-left: none;
  position: relative;
  :hover,
  :focus {
    border-color: ${cssVars.lightGrey};
  }
  :focus {
    //color: ${cssVars.black};
    svg {
      scale: 1.3;
    }
  }
`;

const generateHref = (data: { _index: string; _source: { id: number; accountId?: number } }) => {
  if (data._index.includes('document')) return `/account/${data._source.accountId}/document/${data._source.id}/`;
  if (data._index.includes('account')) return `/account/${data._source.id}/`;
  return null;
};

function GlobalSearch(props: GlobalSearchProps) {
  const [input, setInput] = useState('');
  const [searchTerm, setSearchTerm] = useState(input);
  const [selected, setSelected] = useState(null);
  const [globalOptions, setGlobalOptions] = useState([]);
  const router = useRouter();
  const isNavigating = useIsNavigating();
  const goBtnRef = useRef<HTMLButtonElement>(null);

  // Using throttle instead of debounce as it seems to be best option in conjunction with signals - debounce would likely make the UI seem too sluggish
  const _setSearch = useThrottledCallback(setSearchTerm, 500, { leading: true, trailing: true });

  useEffect(() => {
    _setSearch(input);
  }, [_setSearch, input]);

  const query = useQuery(
    [`global_search`, searchTerm],
    async ({ signal }) => {
      const res = await axios.get(`${window.location.protocol}//${window.location.host}/api/v1/resource/search`, {
        signal,
        params: {
          sequence_id: 1,
          query: searchTerm,
        },
      });
      return { ...res.data };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      keepPreviousData: true,
      retry: 1,
      enabled: searchTerm?.length > 0,
    },
  );

  useEffect(() => {
    if (query.data?.payload) {
      setGlobalOptions(
        query.data?.payload.map((d) => ({
          ...d,
          href: generateHref(d),
          id: d._id,
          value: d._source.name,
          index: d._index,
          data: d._source,
          text: d._source.name,
          // render: () => <GlobalAutocompleteOption data={d._source} index={d._index} />,
        })),
      );
    }
  }, [query?.data]);

  const optionRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const el = <GlobalAutocompleteOption data={itemProps.dataItem.data} index={itemProps.dataItem.index} focused={itemProps.focused} />;
    return React.cloneElement(li, li.props, el);
  };

  const onChange = (e) => {
    if (typeof e.value === 'string') {
      setInput(e.value);
      setSelected(null);
    }
    if (typeof e.value === 'object') {
      router.push(e.value.href).then(() => {
        // TODO - it may make more sense to add an event listener for page change and reset then
        setInput('');
        setSelected(null);
      });
      // setInput(e.value?.value);
      // setSelected(e.value);
    }
  };

  const listNoDataRender = () => {
    return (
      <div
        css={css`
          padding: 8px;
        `}
      >
        <FormattedMessage defaultMessage={'No Results Found'} description={'Notification that a search did not find any results'} />
      </div>
    );
  };

  const prefix = () => {
    return (
      <InputPrefix>
        <div
          css={css`
            //padding-inline: 8px;
            height: 100%;
            width: 34px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid ${cssVars.lightGrey};
          `}
        >
          <FontAwesomeIcon icon={['fas', 'search']} />
        </div>
      </InputPrefix>
    );
  };

  useEffect(() => {
    if (selected?.href) {
      try {
        console.log(goBtnRef.current.tabIndex);
        goBtnRef.current?.focus();
      } catch (e) {
        console.log(e);
      }
    }
  }, [selected?.href]);

  // @ts-ignore
  return (
    <StyledGlobalSearch className={'global-search'}>
      <StyledAutocomplete
        loading={query.isFetching}
        data={globalOptions}
        value={input}
        onChange={onChange}
        itemRender={optionRender}
        prefix={prefix}
        listNoDataRender={listNoDataRender}
        dataItemKey={'id'}
        id={'global_search'}
      />
      {/*It would be easiest to use the suffix property to attach this, but for some reason it breaks the focus() fn on the ref*/}
      <GoButton
        className={'global-search-go-btn'}
        ref={goBtnRef}
        onClick={() => {
          router.push(selected.href).then(() => {
            // TODO - it may make more sense to add an event listener for page change and reset then
            setInput('');
            setSelected(null);
          });
        }}
        disabled={!selected?.href || isNavigating}
        primary={true}
        busy={isNavigating}
      >
        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
      </GoButton>
    </StyledGlobalSearch>
  );
}

export default GlobalSearch;
