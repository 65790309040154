/** @format */

import { DocumentTemplate } from '@common/application/enums/DocumentTemplate';
import { TableKey } from '@common/application/enums/TableKey';
import { TDocumentStyle } from '@common/application/hooks/useDocument';
import { ShortcutAction } from '@common/application/hooks/useKeyboardShortcuts';

export type TableSettings = {
  [key in TableKey]: TableConfig;
};

export type KeyMapSettings = {
  [key in ShortcutAction]: string;
};

export interface IDocumentDefaults {
  template: DocumentTemplate;
  styles?: TDocumentStyle;
}

export interface IDocumentRefreshSettings {
  autoRefresh: boolean;
  interval: number;
}
export interface IDocumentSettings {
  defaults: IDocumentDefaults;
  refresh: IDocumentRefreshSettings;
}

// If ISetting accepts any new properties, they will need to be added here too
export type TSettings = TableSettings | KeyMapSettings | IDocumentSettings;

export interface ISetting {
  tableSettings: TableSettings;
  // tableSettings: Record<TableKey, TableConfig>;
  keyMap: KeyMapSettings;
  document: IDocumentSettings;
}

export interface TableConfig {
  defaultSort: { columnId: string; ascending: boolean };
  hiddenColumns: string[];
  columns: string[];
  mandatoryColumns: string[];
}

function useDefaultSettings(): ISetting {
  return {
    tableSettings: {
      [TableKey.ACCOUNT]: {
        defaultSort: { columnId: 'name', ascending: true },
        hiddenColumns: ['createdAt', 'actions'],
        columns: ['logo', 'name', 'description', 'savingsTotal', 'accountNumber', 'nationalAccountNumber', 'createdAt', 'summary', 'actions'],
        mandatoryColumns: ['name'],
      },
      [TableKey.DOCUMENT]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['id', 'title', 'description', 'author', 'createdBy', 'updatedAt', 'createdAt', 'actions', 'savingsTotal'],
        mandatoryColumns: ['title'],
      },
      [TableKey.ATTACHMENTS]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: [],
        columns: ['description', 'attachmentType', 'createdAt', 'download', 'actions'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_ACCOUNT]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'name', 'company', 'accountNumber', 'description', 'industry', 'nationalAccountNumber', 'extraAccountNumbers', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_ACCOUNT_APPROVE_INDUSTRY]: {
        defaultSort: { columnId: 'name', ascending: true },
        hiddenColumns: ['updatedAt'],
        columns: ['id', 'name', 'accountNumber', 'industry'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_ACCOUNT]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'name', 'company', 'accountNumber', 'description', 'industry', 'nationalAccountNumber', 'extraAccountNumbers', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_BRANCH]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'company', 'erpId', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_BRANCH]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'company', 'erpId', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_COMPANY]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'name', 'realm', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_COMPANY]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'name', 'realm', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_COMPANY_BRAND]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'brand', 'company', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['brand'],
      },
      [TableKey.ADMIN_DELETE_COMPANY_BRAND]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['logo', 'brand', 'company', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['brand'],
      },
      [TableKey.ADMIN_CONTACT]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'role', 'phone', 'email', 'siteName', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_APPROVE_CONTACT]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'role', 'phone', 'email', 'siteName', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_CONTACT]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'role', 'phone', 'email', 'siteName', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_HELP]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['companyName', 'localeId', 'title', 'description', 'url', 'helpTypeId', 'embed', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['title'],
      },
      [TableKey.ADMIN_DELETE_HELP]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['companyName', 'localeId', 'title', 'description', 'url', 'helpTypeId', 'embed', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['title'],
      },
      [TableKey.ADMIN_SITE]: {
        defaultSort: { columnId: 'createdAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'accountName', 'siteAccountNumber', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_SITE]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'accountName', 'siteAccountNumber', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_INDUSTRY]: {
        defaultSort: { columnId: 'name', ascending: true },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'createdAt', 'updatedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_DELETE_INDUSTRY]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['name', 'createdAt', 'updatedAt', 'deletedAt'],
        mandatoryColumns: ['name'],
      },
      [TableKey.ADMIN_ERROR_LOG]: {
        defaultSort: { columnId: 'dateTime', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['id', 'message', 'stack', 'fixed', 'dateTime'],
        mandatoryColumns: ['id'],
      },
      [TableKey.ADMIN_DELETED_DOCUMENT]: {
        defaultSort: { columnId: 'deletedAt', ascending: false },
        hiddenColumns: ['updatedAt'],
        columns: ['id', 'title', 'deletedAt', 'createdAt'],
        mandatoryColumns: ['id'],
      },
    },
    keyMap: {
      [ShortcutAction.SAVE]: 'Ctrl+S',
      [ShortcutAction.LOAD]: 'Ctrl+L',
      [ShortcutAction.ATTACHMENTS]: 'Ctrl+Shift+A',
      [ShortcutAction.SAVEAS]: 'Ctrl+Shift+S',
      [ShortcutAction.SAVETEMPLATE]: 'Ctrl+Shift+T',
      [ShortcutAction.TOGGLEPERCENT]: 'Ctrl+Shift+P',
      [ShortcutAction.NEWDOC]: 'Ctrl+Alt+N',
      [ShortcutAction.DOWNLOAD]: 'Ctrl+Shift+D',
      [ShortcutAction.DOWNLOAD_ALL]: 'Ctrl+Alt+Shift+D',
      [ShortcutAction.MAIL]: 'Ctrl+M',
      [ShortcutAction.PRINT]: 'Ctrl+P',
      [ShortcutAction.TOGGLEIMAGES]: 'Ctrl+I',
    },
    document: {
      defaults: { template: DocumentTemplate.V2 },
      refresh: { autoRefresh: true, interval: 2000 },
    },
  };
}

export default useDefaultSettings;
